import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

const config = require("../../../meta/config");
mapboxgl.accessToken = config.mapboxAccessToken;

export default function MapBox({ lat, lng, zoom, markers }) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [latitude, setLatitude] = useState(lat || -33.9486823);
    const [longitude, setLongitude] = useState(lng || 151.2550449);
    const [mapZoom, setMapZoom] = useState(zoom || 15);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        if (longitude && latitude && mapZoom) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11",
                center: [longitude, latitude],
                zoom: mapZoom,
                scrollZoom: false,
                touchZoomRotate: true,
            });

            if (markers && markers.length > 1) {
                const bounds = new mapboxgl.LngLatBounds([
                    markers[0],
                    markers[0],
                ]);
                markers.forEach((marker) => {
                    bounds.extend([marker.lng, marker.lat]);
                });
                map.current.fitBounds(bounds, { padding: 60 });
            }
            const nav = new mapboxgl.NavigationControl({ showCompass: false });
            map.current.addControl(nav, "top-right");
        }
    });
    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on("move", () => {
            setLatitude(map.current.getCenter().lat.toFixed(4));
            setLongitude(map.current.getCenter().lng.toFixed(4));
            setMapZoom(map.current.getZoom().toFixed(2));
        });
    });

    useEffect(() => {
        if (!map.current) return;
        markers &&
            markers.forEach((item) => {
                new mapboxgl.Marker({ color: "#515e65" })
                    .setLngLat([item.lng, item.lat])
                    .addTo(map.current);
            });
    }, [map, markers]);

    return (
        <>
            <Helmet>
                <link
                    href="https://api.mapbox.com/mapbox-gl-js/v2.3.1/mapbox-gl.css"
                    rel="stylesheet"
                />
            </Helmet>
            <div ref={mapContainer} className="map-container h-full" />
        </>
    );
}
