import React from "react";

import classNames from "classnames";
import { Link } from "gatsby";
import { useModal } from "../../../provider";

import { CheckIcon } from "@heroicons/react/outline";
import { gaEvent } from "../../helpers";

export default function StepsToStart({ id, className }) {
  const modal = useModal();

  const steps = [
    {
      name: "Sign Up for your Free Trial",
      description:
        "If you haven't already, sign up for your free trial to get started!",
      onClick: () => {
        gaEvent(
          "Button",
          "FreeTrial",
          "free trial - getting started timeline to modal"
        );
        modal.openFreeTrialModal();
      },
      status: "complete",
    },
    {
      name: "Speak with your Trainer",
      description:
        "Your FEAT Trainer will be in contact with you shortly to learn what you are looking to get out of your training. We'll also provide you all the information about your first session and answer any questions you may have.",
      status: "current",
    },
    {
      name: "Complete your pre-training questionnaire",
      description:
        "If you haven't already, please make sure you complete your pre-training questionnaire. This helps us know a bit more about you and where you're at health-wise.",
      status: "upcoming",
    },
    {
      name: "Your First Session",
      description:
        "On your first session, arrive 10 minutes early - this is a great opportunity for your trainer to meet you face-to-face and run you through how your session will work. Bring your water bottle, towel and comfy training clothes including running/training shoes.",
      status: "upcoming",
    },
    {
      name: "Your First Week",
      description:
        "Your first week of training is a free trial. Here you'll get to try out as many sessions as you'd like and see our varied training styles at FEAT. No two days are ever the same in our overall strength and conditioning program.",
      status: "upcoming",
    },
    {
      name: "Join the FEAT Family",
      description:
        "After your first week, you get to join us as a member and get to keep training with FEAT and start building those connections with others in your community whilst keeping fit and healthy in the fresh air.",
      status: "upcoming",
    },
  ];

  return (
    <div className={classNames("px-6 sm:px-16", className)}>
      <div
        className="pt-12 lg:pt-16 pb-8 lg:pb-12 text-feat-darkgray max-w-screen-lg mx-auto"
        id={id}
      >
        {/* <h2 className="text-heading uppercase text-4xl text-feat-darkgray text-center"> */}
        <h2 className="text-center text-heading uppercase text-3xl md:text-4xl lg:text-5xl text-feat-blue">
          Getting Started
        </h2>
        <h3 className="text-center pt-6 text-base text-feat-darkgray-300">
          Here's what to know before you get started
        </h3>
        <p className="pt-8 font-light">
          The FEAT Fitness training program is an overall strength and
          conditioning program that can be tailored to any fitness level.
        </p>
        <ol className="overflow-hidden mx-auto py-6">
          {steps.map((step, stepIdx) => (
            <li
              key={step.name}
              className={classNames(
                stepIdx !== steps.length - 1 ? "pb-10" : "",
                "relative"
              )}
            >
              {step.status === "complete" ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-feat-blue"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex items-start group">
                    <span className="h-9 flex items-center">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-feat-blue rounded-full">
                        <CheckIcon
                          className="w-5 h-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span
                        className={classNames(
                          "text-xs font-semibold tracking-wide uppercase",
                          {
                            "text-feat-orange underline cursor-pointer hover:text-feat-orange-600":
                              step.onClick,
                          }
                        )}
                        onClick={step.onClick}
                      >
                        {step.name}
                      </span>
                      <span className="text-sm text-gray-500">
                        {step.description}
                      </span>
                    </span>
                  </div>
                </>
              ) : step.status === "current" ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div
                    className="relative flex items-start group"
                    aria-current="step"
                  >
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-feat-blue rounded-full">
                        <span className="h-2.5 w-2.5 bg-feat-blue rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-feat-blue-600">
                        {step.name}
                      </span>
                      <span className="text-sm text-gray-500">
                        {step.description}
                      </span>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex items-start group">
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full">
                        <span className="h-2.5 w-2.5 bg-transparent rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                        {step.name}
                      </span>
                      <span className="text-sm text-gray-500">
                        {step.description}
                      </span>
                    </span>
                  </div>
                </>
              )}
            </li>
          ))}
        </ol>
        <div className="font-light ">
          Still have some questions? Head over to our{" "}
          <Link to="/faqs" className="text-feat-blue underline cursor-pointer">
            FAQs
          </Link>{" "}
          to read more about training with FEAT Fitness.
        </div>
      </div>
    </div>
  );
}
