import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Button from "../../components/Button";
import MapBox from "../../components/MapBox";

export default function OurLocations({ id }) {
    const locationData = useStaticQuery(graphql`
        query {
            allMarkdownRemark(
                filter: {
                    frontmatter: { templateKey: { eq: "location-page" } }
                }
                sort: { order: ASC, fields: [frontmatter___order] }
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            location
                            lat
                            lng
                            sub_category
                            slug
                        }
                    }
                }
            }
        }
    `);
    const locations = locationData.allMarkdownRemark.edges;
    const markers = locations.map(({ node }) => ({
        lng: node.frontmatter.lng,
        lat: node.frontmatter.lat,
    }));
    return (
        <div id={id}>
            {/* Map and Location Details */}
            <div className="flex flex-col-reverse h-screen-navbar lg:h-auto lg:mx-auto lg:grid lg:grid-cols-2 lg:gap-8 bg-gray-100 pt-12 lg:pt-0">
                <div className="flex-1 h-full z-0 overflow-y-auto focus:outline-none">
                    <MapBox markers={markers} />
                </div>
                <div className="flex-1 pb-12 md:py-24 px-6 lg:px-0 lg:pr-6 text-feat-darkgrey">
                    <h2 className="font-heading uppercase text-3xl">
                        Maroubra Beach
                    </h2>
                    <div className="font-base pt-8">
                        Maroubra is such a beautiful location to be training
                        outdoors. With the ability to run sessions on both the
                        sand and grass, you'll never be bored with the variety
                        of sessions FEAT provides.
                    </div>
                    <Link to="/location/maroubra-sydney">
                        <Button className="bg-feat-blue text-white mt-4">
                            Read More
                        </Button>
                    </Link>
                </div>
            </div>
            {/* END Map and Location Details */}
        </div>
    );
}
